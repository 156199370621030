// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    client_id: 'adcd88a9-8ed3-4440-ba05-cf0fa86bfffd',
    tenant_id: '65077b68-6db1-4a06-b930-a013aecff707',
    isLocal: true,
    isLoginMock: true,
    base_graphql: 'https://euro-pgd-service.duka.dev/graphql/',
    base_url: 'https://euro-pgd-service.duka.dev/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
